<template>
  <div id="login">
    <div class="login_input">
      <h1>智慧环卫平台</h1>
      <div>
        <el-input v-model="username" placeholder="请输入账号"></el-input>
      </div>
      <div>
        <el-input
          placeholder="请输入密码"
          v-model="password"
          show-password
        ></el-input>
      </div>
      <div class="login_button">
        <el-button type="primary" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginAPI, GetLeftMenu } from "../request/api";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      // username: "13424489221",
      // password: "112233",
    };
  },
  computed: {},
  mounted() {
    // this.login()
  },
  methods: {
    //登录
    async login() {
      const base1 = this.encode(this.password);
      let basearr = base1.split("");
      let basearr0 = basearr[0];
      basearr[0] = basearr[2];
      basearr[2] = basearr0;
      const base2 = this.encode(basearr.join(""));
      let res = await LoginAPI({
        user_phone: this.username,
        password: base2,
      });

      if (res.code === 1) {
        sessionStorage.clear();
        sessionStorage.setItem("token", res.data.info.token);
        sessionStorage.setItem("user_name", res.data.info.user_name);
        this.$store.commit("setusername", res.data.info.user_name);
        this.$store.commit("setuserphone", res.data.info.user_phone);
        this.$router.push("/");
        this.$message({
          message: "登录成功",
          type: "success",
        });
        let res1 = await GetLeftMenu();
        const info = res1.data.info.filter((item) => {
          return item.id > 2;
        });
        this.$store.commit("setmenuinfo", info);
      } else {
        this.$message.error(res.msg);
      }
    },
    encode(str) {
      if (str === undefined || str === "" || str === null) {
        return str;
      }
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100vh;
  background-color: #101838;
  display: flex;
  justify-content: center;
  align-items: center;

  .login_input {
    width: 400px;
    height: 400px;

    div {
      margin-top: 5px;

      .el-input__inner {
        background-color: #000 !important;
      }
    }

    .login_button {
      margin-top: 11px;
    }
  }

  h1 {
    color: #fff;
    font-size: 34px;
    text-align: center;
  }

  .el-button {
    width: 100%;
  }
}
</style>
